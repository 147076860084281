<template>
  <div id="index">
    <Header nav="1"></Header>

    <div class="loading" v-if="showloading">
      <van-loading type="spinner" color="#206C47" size="24px" vertical>
        加载中...
      </van-loading>
    </div>

    <div class="container" v-else>
      <!-- 轮播图 -->
      <div class="banner">
        <van-swipe
          class="my-swipe"
          ref="swiper"
          :autoplay="autoplay"
          indicator-color="white"
        >
          <van-swipe-item v-for="item in bannerImg" :key="item.image">
            <img class="bannerImg" :src="item.image" alt="" />
          </van-swipe-item>
        </van-swipe>
        <!-- 左右箭头 -->
        <div class="arrow">
          <img src="../assets/left.png" @click="changeSwipe('up')" />
          <img src="../assets/right.png" @click="changeSwipe('down')" />
        </div>
      </div>

      <!-- 公司简介 -->
      <div class="company">
        <div class="en"><span class="En">C</span><span>OMPANY</span></div>
        <div class="ch">公司简介</div>
        <div class="pageContent1">
          <div class="pageTitle pageTitle1">{{ company.name }}</div>
          <div class="pageText">{{ company.content }}</div>
          <div class="more more1" @click="getmore('/aboutUs')">
            <div>查看更多</div>
            <van-icon name="play" />
          </div>
        </div>
        <div class="pageimg1">
          <img class="pageImg1" :src="company.image" />
        </div>
      </div>

      <!-- 核心商品 -->
      <div class="products">
        <div class="en"><span class="En">C</span><span>ORE PRODUCTS</span></div>
        <div class="ch">核心商品</div>
        <div class="pageContent2">
          <div
            class="page2Item"
            v-for="item in products"
            :key="item.id"
            @click="getDetails(item.id)"
          >
            <div class="pageimg2">
              <img class="pageImg2" :src="item.imgurl" />
            </div>
            <div class="pageName">
              <div class="nameTxt">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="more more2" @click="getmore('/products')">
          <div>查看更多</div>
          <van-icon name="play" />
        </div>
      </div>
    </div>

    <div class="news">
      <div class="en"><span class="En">L</span><span>ATEST NEWS</span></div>
      <div class="ch">最新资讯</div>
      <div class="pageContent3">
        <div class="page3Item" v-for="item in news" :key="item.Article_id" @click="getdetail(item.Article_id)">
          <div class="left">
            <img class="leftimg" :src="item.Article_imgurl" />
          </div>
          <div class="right">
            <div class="pageTitle3" :title="item.Article_title">
              {{ item.Article_title }}
            </div>
            <!-- <div class="pageText3">{{ item.Article_prompt }}</div> -->
            <div class="pageTime3">{{ item.add_date }}</div>
          </div>
        </div>
      </div>
      <div class="more more3" @click="getmore('/news')">
        <div>查看更多</div>
        <van-icon name="play" />
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Loading, Icon } from "vant";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "index",
  components: {
    Header,
    Footer,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Loading.name]: Loading,
    [Icon.name]: Icon,
  },
  data() {
    return {
      baseURL: "http://api.gzd1.net/", //图片基地址
      bannerImg: [],
      autoplay: 3000, //自动轮播时间
      company: {},
      products: [],
      news: [],
      showloading: true,
    };
  },
  mounted() {
    // 轮播图
    this.$axios
      .post("https://api1.pyua.net/index/banner", { type: 2 })
      .then((res) => {
        // console.log(res);
        if (res && res.code == 1) {
          this.bannerImg = res.data.arr;
          // console.log(this.bannerImg);
          setTimeout(() => {
            this.showloading = false;
          }, 300);
        }
      });

    // 公司简介
    this.$axios
      .post("https://api1.pyua.net/index/content", { type: 2 })
      .then((response) => {
        if (response && response.code == 1) {
          this.company = response.data.arr[0];
        }
      });

    // 核心商品
    this.$axios
      .post(
        "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=product&m=tu_product"
      )
      .then((res) => {
        // console.log(res.pro);
        if (res.status == 1) {
          this.products = res.pro.slice(0, 6);
        }
      });

    // 最新资讯
    this.$axios
      .post(
        "https://mallxadmin.pyua.net/LKT/index.php?module=api&action=envelope&m=act_list"
      )
      .then((res) => {
        // console.log(res.article);
        this.news = res.article;
      });
  },
  methods: {
    //点击左右按钮实现左右切换
    changeSwipe(type) {
      this.autoplay = 0;
      if (type == "up") {
        this.$refs.swiper.prev();
      } else if (type == "down") {
        this.$refs.swiper.next();
      }
      setTimeout(() => {
        this.autoplay = 3000;
      }, 3000);
    },

    getmore(url) {
      this.$router.push(url);
      this.moreActive = -1;
    },

    // 跳转到商品详情页
    getDetails(id) {
      // console.log(id);
      this.$router.push({ path: `/productDetail?id=${id}` });
    },

        // 跳转到新闻详情页
    getdetail(id) {
      // console.log(id);
      this.$router.push({ path: `/newsDetail?id=${id}` });
    },
  },
};
</script>


<style lang="less" scoped>
.container {
  margin-top: 2.75rem;
  box-sizing: border-box;
  line-height: 1;
  .banner {
    position: relative;
  }
  .my-swipe {
    .bannerImg {
      width: 100%;
    }
  }
  .arrow {
    padding: 0 1rem;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  /deep/ .van-swipe__indicators {
    bottom: 2.75rem;
  }
  /deep/ .van-swipe__indicator {
    width: 0.625rem;
    height: 0.625rem;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 60%;
}

.en {
  font-family: "DIN";
  margin-top: 2.9375rem;
  margin-bottom: 0.3125rem;
  color: #d5e1db;
  font-size: 18px;
  line-height: 1;
  .En {
    font-size: 28px;
    line-height: 1;
  }
}
.ch {
  line-height: 1;
  color: #206c47;
  font-weight: 700;
  font-size: 18px;
}

.pageTitle {
  color: #206c47;
  font-size: 16px;
}
.pageText {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
}
.more {
  color: #206c47;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.company {
  display: flex;
  flex-direction: column;
  align-items: center;
  .pageContent1 {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pageTitle1 {
      margin: 1.875rem 0 1.5rem 0;
    }
    .more1 {
      margin: 1.875rem 0;
    }
  }
  .pageimg1 {
    padding: 0 1rem;
    margin-bottom: 3.0625rem;
    .pageImg1 {
      width: 100%;
    }
  }
}

.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f8f6;
  .pageContent2 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.875rem;
    .page2Item {
      margin-bottom: 1.5rem;
      margin-left: 0.75rem;
      width: calc((100vw - 2rem) / 2);
      box-shadow: 0 0 5px 1px #e7e7e7;
      // border: 1px solid saddlebrown;
      .pageimg2 {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        .pageImg2 {
          margin-top: 0.125rem;
          width: calc((100vw - 3rem) / 2 - 4px);
        }
      }
      .pageName {
        color: #206c47;
        width: 100%;
        background-color: #fff;
        height: 7.3125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // white-space:nowrap;
        .nameTxt {
          width: 100%;
          font-size: 14px;
          padding: 0 5px;
          box-sizing: border-box;
          line-height: 20px;
          // white-space:nowrap;
          display: -webkit-box;
          box-orient: vertical;
          -webkit-box-orient: vertical;
          text-overflow:ellipsis;
          overflow:hidden;
          line-clamp: 2;
          -webkit-line-clamp: 2;
          // border: 1px solid saddlebrown;
          // height: 2.5rem;
        }
      }
    }
  }
  .more2 {
    margin: 0.375rem 0 3rem 0;
  }
}

.news {
  display: flex;
  flex-direction: column;
  align-items: center;
  .pageContent3 {
    margin: 30px 0;
    .page3Item {
      display: flex;
      padding: 1.5rem 0;
      margin: 0 1rem;
      border-bottom: 1px solid #e0e0e0;
      &:nth-child(1) {
        padding-top: 0;
      }
      .left {
        width: 9.0625rem;
        .leftimg {
          width: 9.0625rem;
        }
      }
      .right {
        flex: 1;
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .pageTitle3 {
          color: #206c47;
          font-size: 0.875rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        // .pageText3 {
        //   color: #666666;
        //   font-size: 0.75rem;
        //   width: calc(100vw - 3rem - 9.0625rem);
        //   white-space: nowrap; /*超出的空白区域不换行*/
        //   overflow: hidden; /*超出隐藏*/
        //   text-overflow: ellipsis; /*文本超出显示省略号*/
        // }
        .pageTime3 {
          color: #999999;
          font-size: 0.625rem;
          font-family: "DIN2";
        }
      }
    }
  }
  .more3 {
    margin: 0.375rem 0 3rem 0;
  }
}
</style>
